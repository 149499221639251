@charset "UTF-8";

/// Georgia font stack.
///
/// @type List

$georgia: "Georgia", "Cambria", "Times New Roman", "Times", serif;

/// Helvetica font stack.
///
/// @type List

$helvetica: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;

/// Lucida Grande font stack.
///
/// @type List

$lucida-grande: "Lucida Grande", "Tahoma", "Verdana", "Arial", sans-serif;

/// Monospace font stack.
///
/// @type List

$monospace: "Bitstream Vera Sans Mono", "Consolas", "Courier", monospace;

/// Verdana font stack.
///
/// @type List

$verdana: "Verdana", "Geneva", sans-serif;
