.landing {
  height: 100vh;
  width: 100vw;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    color: white;
    text-align: center;
    margin-top: -50px;

    h1 {
      font-size: 48px;
      opacity: 0; }

    p {
      font-size: 18px;
      opacity: 0; } }

  .scrolling-mouse {
    opacity: 0;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; } }

@include mq($max-width: 1024px) {
  .landing {
    background-attachment: scroll; } }
