@function _shape-size-stripper($shape-size) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `_shape-size-stripper` is " +
    "deprecated and will be removed in 5.0.0.";
  }

  $shape-size-spec: null;
  @each $value in $shape-size {
    @if ($value == "cover") or ($value == "contain") {
      $value: null;
    }
    $shape-size-spec: "#{$shape-size-spec} #{$value}";
  }
  @return $shape-size-spec;
}
