.content-fade {
  opacity: 0 !important; }

.empty-result {
  text-align: center;
  font-weight: lighter;
  color: #333; }

.main-section {
  padding-top: 50px;
  padding-bottom: 20px;

  .row {
    margin-bottom: 20px; }

  .section-title {
    opacity: 0; }

  .section-title,
  .caption > h3 {
    text-transform: uppercase;
    color: $navbar-active-bg; }

  .section-separator-col {
    display: flex;
    justify-content: center;
    opacity: 0;

    .section-title-separator {
      width: 300px;
      height: 3px;
      margin-bottom: 20px;
      background-color: $section-separator-bg;
      display: flex;
      justify-content: center;

      div {
        height: 3px;
        width: 40px;
        background-color: $brand-primary; } } } }

.page-content {
  padding-top: 80px; }
