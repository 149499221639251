@mixin columns($arg: auto) {
  @include _bourbon-deprecate-for-prefixing("columns");

  // <column-count> || <column-width>
  @include prefixer(columns, $arg, webkit moz spec);
}

@mixin column-count($int: auto) {
  @include _bourbon-deprecate-for-prefixing("column-count");

  // auto || integer
  @include prefixer(column-count, $int, webkit moz spec);
}

@mixin column-gap($length: normal) {
  @include _bourbon-deprecate-for-prefixing("column-gap");

  // normal || length
  @include prefixer(column-gap, $length, webkit moz spec);
}

@mixin column-fill($arg: auto) {
  @include _bourbon-deprecate-for-prefixing("column-fill");

  // auto || length
  @include prefixer(column-fill, $arg, webkit moz spec);
}

@mixin column-rule($arg) {
  @include _bourbon-deprecate-for-prefixing("column-rule");

  // <border-width> || <border-style> || <color>
  @include prefixer(column-rule, $arg, webkit moz spec);
}

@mixin column-rule-color($color) {
  @include _bourbon-deprecate-for-prefixing("column-rule-color");

  @include prefixer(column-rule-color, $color, webkit moz spec);
}

@mixin column-rule-style($style: none) {
  @include _bourbon-deprecate-for-prefixing("column-rule-style");

  // none | hidden | dashed | dotted | double | groove | inset | inset | outset | ridge | solid
  @include prefixer(column-rule-style, $style, webkit moz spec);
}

@mixin column-rule-width($width: none) {
  @include _bourbon-deprecate-for-prefixing("column-rule-width");

  @include prefixer(column-rule-width, $width, webkit moz spec);
}

@mixin column-span($arg: none) {
  @include _bourbon-deprecate-for-prefixing("column-span");

  // none || all
  @include prefixer(column-span, $arg, webkit moz spec);
}

@mixin column-width($length: auto) {
  @include _bourbon-deprecate-for-prefixing("column-width");

  // auto || length
  @include prefixer(column-width, $length, webkit moz spec);
}
