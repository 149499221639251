@charset "UTF-8";
/// Determine if a column count has been given.
/// If no columns have been given return the grid's total column count.
///
/// @argument {map} $grid
///
/// @argument {number (unitless) | null} $columns
///
/// @return {number}
///
/// @example scss
///   _neat-column-default($neat-grid, 4)
///
/// @access private

@function _neat-column-default($grid, $columns) {
  @if $columns == null {
    $_grid-columns: _retrieve-neat-setting($grid, columns);
    @return $_grid-columns;
  } @else {
    @return $columns;
  }
}
