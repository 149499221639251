// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

@function em($pxval, $base: $em-base) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `em` is deprecated and will be " +
    "removed in 5.0.0.";
  }

  $user-deprecation-warnings-setting: $output-bourbon-deprecation-warnings;
  $output-bourbon-deprecation-warnings: false !global;

  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }

  $output-bourbon-deprecation-warnings: $user-deprecation-warnings-setting !global;

  @return ($pxval / $base) * 1em;
}
