$bg-and-border: #fefefe;

@include keyframes(scroll) { // Animated circle keyframes
  0% { opacity: 0; }
  25% { @include transform(translateY(0)); opacity: 1; }
  100% { @include transform(translateY(10px)); opacity: 0; }
}

.caption { margin-top: 10px; color: transparentize($bg-and-border, 0.4); } // Margin for dummy text

.mouse { // Mouse icon
  display: inline-block;
  @include size(28px 44px); // Define sizes
  border-radius: 12px; // Define edge radius
  border: 2px solid $bg-and-border; // Edge width and colors
  text-align: center; // Center animated circle in mouse icon
  &:after { // Animated circle
    display: inline-block;
    @include size(8px); // Circle size
    background-color: $bg-and-border; // Circle color
    border-radius: 50%; // Become rounded corner
    content: "";
    @include animation(scroll 1.2s ease-out infinite forwards); // Keyframe animation
  }
}
