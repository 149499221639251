@function _linear-gradient-parser($image) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `_linear-gradient-parser` is " +
    "deprecated and will be removed in 5.0.0.";
  }

  $user-deprecation-warnings-setting: $output-bourbon-deprecation-warnings;
  $output-bourbon-deprecation-warnings: false !global;

  $image: unquote($image);
  $gradients: ();
  $start: str-index($image, "(");
  $end: str-index($image, ",");
  $first-val: str-slice($image, $start + 1, $end - 1);

  $prefix: str-slice($image, 1, $start);
  $suffix: str-slice($image, $end, str-length($image));

  $has-multiple-vals: str-index($first-val, " ");
  $has-single-position: unquote(_position-flipper($first-val) + "");
  $has-angle: is-number(str-slice($first-val, 1, 1));

  @if $has-multiple-vals {
    $gradients: _linear-side-corner-parser($image, $first-val, $prefix, $suffix, $has-multiple-vals);
  }

  @else if $has-single-position != "" {
    $pos: unquote($has-single-position + "");

    $gradients: (
      webkit-image: -webkit- + $image,
      spec-image: $prefix + "to " + $pos + $suffix
    );
  }

  @else if $has-angle {
    // Rotate degree for webkit
    $gradients: _linear-angle-parser($image, $first-val, $prefix, $suffix);
  }

  @else {
    $gradients: (
      webkit-image: -webkit- + $image,
      spec-image: $image
    );
  }

  $output-bourbon-deprecation-warnings: $user-deprecation-warnings-setting !global;

  @return $gradients;
}
