@charset "UTF-8";
/// Parse media types. If the property is a string then return it, otherwise
/// assume screen and min-width.
///
/// @argument {string | number (with unit)} $media
///
/// @return {number}
///
/// @example scss
///   _neat-parse-media($grid, 1000px)
///
/// @access private

@function _neat-parse-media($media) {
  @if type-of($media) == number {
    @return "only screen and (min-width: #{$media})";
  } @else if type-of($media) == string {
    @return "#{$media}";
  }
}
