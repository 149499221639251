@charset "UTF-8";
/// Return a calulated column width that can then be passed in to a `calc()`.
///
/// @argument {map} $grid
///
/// @argument {number} $columns
///
/// @return {string}
///
/// @example scss
///   _neat-column-width($neat-grid, 4)
///
/// @access private

@function _neat-column-width($grid, $columns) {
  $_column-ratio: _neat-column-ratio($grid, $columns);
  $_gutter: _retrieve-neat-setting($grid, gutter);

  @if $_gutter == 0 {
    @return unquote("#{percentage($_column-ratio)}");
  } @else {
    $_gutter-affordance: $_gutter + ($_gutter * $_column-ratio);
    @return unquote("#{percentage($_column-ratio)} - #{$_gutter-affordance}");
  }
}
