.latest-project-card {
  background-color: white;
  padding: 0px;
  display: flex;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

  .image {
    flex-grow: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

  .info {
    width: 300px;
    padding: 20px;

    h4 {
      color: $navbar-active-bg; }

    p {
      color: $navbar-default-bg;
      font-weight: lighter;
      padding: 20px 0px; }

    .raised-progressbar {
      width: 100%;
      height: 4px;
      background-color: $gray-bg;

      div {
        height: 4px;
        background-color: $brand-primary;
        max-width: 100%; } }

    .project-money {
      display: flex;

      p {
        width: 50%;
        padding: 10px;
        color: $text-color;

        &:first-child {
          text-align: left; }

        &:last-child {
          text-align: right; } } }

    a {
      text-decoration: none; }

    .donate-button {
      background-color: #ccc;
      color: white;
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;

      &.not-completed {
        background-color: $brand-primary; }

      &.not-completed:hover {
        animation: pulse 1s infinite; } } } }

.gallery-container {
  margin: 50px auto;
  max-height: 220px;
  overflow: scroll;
  display: flex;

  .gallery-images {
    width: 999999px;
    min-width: 100%;
    display: flex;
    text-align: center;

    .empty-result {
      width: 100%; }

    .gallery-image {
      height: 220px;
      width: 220px;
      background-position: center;
      background-size: cover;
      margin-right: 15px; } } }


.project-thumbnail {
  padding: 20px;
  border-radius: 0px;
  border: 0px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

  .image {
    width: calc(100% + 40px);
    height: 180px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -20px;
    margin-top: -20px; }

  h4 {
    color: $navbar-active-bg;
    font-weight: lighter;
    padding: 0px 0px 50px; }

  .raised-progressbar {
    width: 100%;
    height: 4px;
    background-color: $gray-bg;

    div {
      height: 4px;
      background-color: $brand-primary;
      max-width: 100%; } }

  .project-money {
    display: flex;

    p {
      width: 50%;
      padding: 10px;
      color: $text-color;

      &:first-child {
        text-align: left; }

      &:last-child {
        text-align: right; } } }

  a {
    text-decoration: none; } }


@include mq($max-width: 1024px) {
  .latest-project-card {
    .image {
      background-position: scroll; } } }

@include mq($max-width: 992px) {
  .latest-project-card {
    border-radius: 0px;

    .image {
      border-radius: 0px; } } }

@include mq($max-width: 560px) {
  .latest-project-card {
    flex-direction: column;

    .image {
      height: 300px; }

    .info {
      width: 100%; } } }
