@mixin placeholder {
  @include _bourbon-deprecate-for-prefixing("placeholder");

  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}
