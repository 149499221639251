.navbar-aircraft {
  position: fixed;
  top: 20px;
  left: 0px;
  right: 0px;
  z-index: 100;

  .navbar-floating {
    opacity: 0;
    background-color: $navbar-default-bg;
    border: none;
    border-radius: 50px;
    height: 50px;

    .container-fluid {
      padding: 0px; }

    .nav {
      a {
        background: $navbar-default-bg;
        color: white;
        transition: all 0.2s ease-in-out;

        &.cta-link {
          background-color: $brand-primary;
          color: white;
          border-radius: 0px 50px 50px 0px;
          padding-left: 0px;

          &:hover {
            background-color: $brand-dark;
            color: white;

            .triangle-separator {
              border-bottom: 25px solid $brand-dark;
              border-right: 25px solid $brand-dark; } } }

        &:hover,
        &:focus {
          background-color: $navbar-active-bg;
          color: $brand-primary; } }

      .dropdown-menu {
        background-color: $navbar-default-bg;
        border: none;
        top: 30px;

        li {
          a {
            border-bottom: 1px solid $navbar-separator-bg; }

          &:last-of-type {
            a {
              border-bottom: none; } } } } } } }

.navbar-toggle {
  .icon-bar {
    background-color: white;
    transform-origin: center;
    transition: all 0.2s ease-in-out;


    &:nth-of-type(2) {
      transform: rotateZ(-45deg) translateX(-8px); }
    &:nth-of-type(3) {
      opacity: 0; }
    &:nth-of-type(4) {
      transform: rotateZ(45deg) translateX(-8px); } }

  &.collapsed {
    .icon-bar {
      &:nth-of-type(2) {
        transform: rotateZ(0deg) translateX(-8px); }
      &:nth-of-type(3) {
        opacity: 1;
        transform: translateX(-8px); }
      &:nth-of-type(4) {
        transform: rotateZ(0deg) translateX(-8px); } } } }


.navbar-collapse {
  box-shadow: none; }

.navbar-brand {
  padding: 5px;
  border-radius: 25px 0px 0px 25px;

  img {
    max-height: 40px; } }

@include mq($min-width: 767px) {
  .navbar-aircraft {
    .navbar-floating {
      .container-fluid {
        .nav {
          .triangle-separator {
            position: absolute;
            top: 0px;
            left: -49px;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-left: 25px solid transparent;
            border-bottom: 25px solid $brand-primary;
            border-right: 25px solid $brand-primary;
            transition: all 0.2s ease-in-out; } } } } } }


@include mq($max-width: 767px) {
  .navbar-aircraft {
    .navbar-floating {
      .container-fluid {
        .nav {
          a {
            text-align: center;

            &.cta-link {
              border-radius: 0px; } }

          .triangle-separator {
            display: none; }

          .dropdown {
            ul.dropdown-menu {
              border-radius: 0px;
              padding-bottom: 15px;
              background-color: $navbar-active-bg;

              li {
                a {
                  background-color: $navbar-active-bg;
                  border-bottom: 1px solid $navbar-separator-bg; }

                &:last-of-type {
                  a {
                    border-bottom: none; } } } } } } } } }

  .navbar-brand {
    padding: 5px 5px 5px 20px; } }
