.main-section#overallstats {
  background-color: #222;
  padding-top: 50px;
  padding-bottom: 20px;

  .col-md-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    color: white;

    img {
      max-height: 60px; }

    .vertical-separator {
      height: 60px;
      width: 1px;
      background-color: white;
      margin: 0px 20px; }

    h1 {
      margin: 0px;
      font-size: 44px; }

    h3 {
      margin: 0px;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: lighter; } } }

@include mq($max-width: 992px) {
  .main-section#overallstats {
    .col-md-4 {
      &:nth-child(1) {
        justify-content: flex-start; }

      &:nth-child(3) {
        justify-content: flex-end; } } } }

@include mq($max-width: 560px) {
  .main-section#overallstats {
    .col-md-4 {
      &:nth-child(1) {
        justify-content: flex-start; }
      &:nth-child(2) {
        justify-content: flex-start; }
      &:nth-child(3) {
        justify-content: flex-start; } } } }
