@function assign-inputs($inputs, $pseudo: null) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `assign-inputs` is deprecated and will be " +
    "removed in 5.0.0.";
  }

  $list: ();

  @each $input in $inputs {
    $input: unquote($input);
    $input: if($pseudo, $input + ":" + $pseudo, $input);
    $list: append($list, $input, comma);
  }

  @return $list;
}
