@charset "UTF-8";
/// Apply Neat's default properties to undefined values within a map.
///
/// @argument {map} $grid
///
/// @return {map}
///
/// @example scss
///   _retrieve-neat-setting($neat-grid)
///
/// @access private

@function _neat-merge-defaults($grid) {
  $_merged-grid: map-merge((
    columns: 12,
    gutter: 20px,
    media: null,
    color: rgba(#00d4ff, 0.25),
    direction: ltr,
  ), $grid);

  @return $_merged-grid;
}
