@charset "UTF-8";

/// Outputs the spec and prefixed versions of the `::selection` pseudo-element.
///
/// @param {Bool} $current-selector [false]
///   If set to `true`, it takes the current element into consideration.
///
/// @example scss - Usage
///   .element {
///     @include selection(true) {
///       background-color: #ffbb52;
///     }
///   }
///
/// @example css - CSS Output
///   .element::-moz-selection {
///     background-color: #ffbb52;
///   }
///
///   .element::selection {
///     background-color: #ffbb52;
///   }

@mixin selection($current-selector: false) {
  @include _bourbon-deprecate-for-prefixing("selection");

  @if $current-selector {
    &::-moz-selection {
      @content;
    }

    &::selection {
      @content;
    }
  } @else {
    ::-moz-selection {
      @content;
    }

    ::selection {
      @content;
    }
  }
}
