@charset "UTF-8";

/// Checks if a list does not contains a value.
///
/// @access private
///
/// @param {List} $list
///   The list to check against.
///
/// @return {Bool}

@function contains-falsy($list) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `contains-falsy` is deprecated and will be " +
    "removed in 5.0.0.";
  }

  @each $item in $list {
    @if not $item {
      @return true;
    }
  }

  @return false;
}
