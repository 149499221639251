.footer-large {
  background-color: #222;

  .contact-info {
    padding: 50px 20px 30px;

    h1 {
      color: white;
      border-bottom: 1px solid $brand-primary;
      font-weight: lighter;
      padding-bottom: 5px; }

    ul {
      list-style: none;

      li {
        color: white;
        font-weight: lighter;
        font-size: 16px; } } }


  footer {
    padding: 20px;
    border-top: 1px solid $brand-primary;
    color: white;
    font-weight: lighter; } }
