.main-section#donateagain {
  background-color: $brand-primary;
  padding-top: 50px;
  padding-bottom: 20px;
  color: white;

  .row {

    .col-md-4 {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        color: $brand-primary;
        background-color: white;
        border: 1px solid white;
        padding: 10px 20px;
        transition: all 0.2s ease-in-out; } } } }

@include mq($max-width: 992px) {
  .main-section#donateagain {
    .row {
      .col-md-4 {
        a {
          margin-top: 20px; } } } } }
