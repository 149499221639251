//************************************************************************//
// Background-image property for adding multiple background images with
// gradients, or for stringing multiple gradients together.
//************************************************************************//

@mixin background-image($images...) {
  @include _bourbon-deprecate-for-prefixing("background-image");

  $webkit-images: ();
  $spec-images: ();

  @each $image in $images {
    $webkit-image: ();
    $spec-image: ();

    @if (type-of($image) == string) {
      $url-str:       str-slice($image, 1, 3);
      $gradient-type: str-slice($image, 1, 6);

      @if $url-str == "url" {
        $webkit-image: $image;
        $spec-image:   $image;
      }

      @else if $gradient-type == "linear" {
        $gradients: _linear-gradient-parser($image);
        $webkit-image:  map-get($gradients, webkit-image);
        $spec-image:    map-get($gradients, spec-image);
      }

      @else if $gradient-type == "radial" {
        $gradients: _radial-gradient-parser($image);
        $webkit-image: map-get($gradients, webkit-image);
        $spec-image:   map-get($gradients, spec-image);
      }
    }

    $webkit-images: append($webkit-images, $webkit-image, comma);
    $spec-images:   append($spec-images,   $spec-image,   comma);
  }

  background-image: $webkit-images;
  background-image: $spec-images;
}
