.main-section#oursponsors {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 0px;
  overflow: hidden;

  .row:last-child {
    margin-top: 20px;
    margin-bottom: 40px;

    ul {
      list-style: none;
      padding: 0px;
      height: 100px;

      li {
        display: inline-block;
        font-size: 0px;

        a {
          min-width: 200px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 10px;

          img {
            max-height: 80px; } } } } } }
