@charset "UTF-8";
/// Return the oppoite of the float direction of the grid.
///
/// @argument {map} $grid
///
/// @return {string}
///
/// @example scss
///   _neat-opposite-direction($neat-grid)
///
/// @access private

@function _neat-opposite-direction($grid) {
  $_direction: _retrieve-neat-setting($grid, direction);
  $_float-direction: null;
  @if $_direction == "ltr" {
      $_float-direction: right;
  } @else if $_direction == "rtl" {
    $_float-direction: left;
  }
  @return $_float-direction;
}
