@function _radial-positions-parser($gradient-pos) {
  @if $output-bourbon-deprecation-warnings == true {
    @warn "[Bourbon] [Deprecation] `_radial-positions-parser` is " +
    "deprecated and will be removed in 5.0.0.";
  }

  $user-deprecation-warnings-setting: $output-bourbon-deprecation-warnings;
  $output-bourbon-deprecation-warnings: false !global;

  $shape-size: nth($gradient-pos, 1);
  $pos:        nth($gradient-pos, 2);
  $shape-size-spec: _shape-size-stripper($shape-size);

  $pre-spec: unquote(if($pos, "#{$pos}, ", null))
             unquote(if($shape-size, "#{$shape-size},", null));
  $pos-spec: if($pos, "at #{$pos}", null);

  $spec: "#{$shape-size-spec} #{$pos-spec}";

  // Add comma
  @if ($spec != "  ") {
    $spec: "#{$spec},";
  }

  $output-bourbon-deprecation-warnings: $user-deprecation-warnings-setting !global;

  @return $pre-spec $spec;
}
