.member-box {
  min-height: 250px;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;

  &:hover {
    .member-image {
      transform: scale(1.1);

      .overlay {
        opacity: 0; } } }

  .member-image {
    width: 250px;
    height: 250px;
    background-position: center;
    background-size: cover;
    position: relative;
    transition: all 0.6s ease-in-out;

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      opacity: 0.4;
      background-color: black;
      transition: all 0.4s ease-in-out; } }

  .member-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 200px);
    position: relative;

    hr {
      width: 30px;
      margin: 0px;
      border-color: $brand-primary; }

    h1 {
      text-transform: uppercase;
      font-size: 20px;
      color: #333;
      margin: 0px 0px 5px; }

    h3 {
      text-transform: uppercase;
      font-size: 12px;
      color: #aaa;
      margin: 5px 0px 0px;
      font-weight: lighter; }

    p {
      margin: 20px 0px 30px;
      font-weight: lighter; }

    .social-icons {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      a {
        border-right: 1px solid $brand-primary;
        padding: 0px 5px;
        height: 15px;

        &:last-child {
          border-right: 0px; }

        img {
          max-width: 15px;
          max-height: 15px;
          transition: all 0.2s ease-in-out; }

        &:hover {
          img {
            transform: scale(1.3); } } } } } }


@include mq($max-width: 560px) {
  .member-box {
    flex-direction: column;
    align-items: center;

    .member-image {
      width: 100%; }

    .member-info {
      align-items: center;
      width: calc(100% - 60px);

      p {
        text-align: center; } } } }
